import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

// Components
import Footer from './components/Footer';
import Navbar from './components/Navbar';

// Pages
import Home from './pages/Home';

function App() {
  return (
    <Router>
      <Navbar/>
        <Switch>
          <Route path="/index.html" exact component={Home}/>
        </Switch>
      <Footer/>
    </Router>
  );
}

export default App;
