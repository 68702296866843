import React from 'react'

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-grid">
        <div className="footer-col footer-col-left">
          <img className="footer-col-img-logo" src="../img/logo-white.png" alt="A"/>
          <p className="footer-col-text">Política de privacidad</p>
        </div>
        <div className="footer-col footer-col-center">

          <div className="footer-col-grid mb-4">
            <div className="footer-img-container">
              <img src="../img/face.png" alt="A"/>
            </div>
            <div className="footer-img-container">
              <img src="../img/ig.png" alt="A"/>
            </div>
            <div className="footer-img-container">
              <img src="../img/in.png" alt="A"/>
            </div>
          </div>
          <p className="footer-col-text">© 2022 Tobroker</p>

        </div>
        <div className="footer-col footer-col-right">
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.tobroker" rel="noreferrer">
            <img className="footer-col-img-btn mb-5" src="../img/play-store.png" alt="A"/>
          </a>
          <a target="_blank" href="https://apps.apple.com/pa/app/tobroker/id1667618949" rel="noreferrer">
            <img className="footer-col-img-btn" src="../img/app-store.png" alt="A"/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer