import React, {useState, useEffect} from 'react'
import {NavLink} from 'react-router-dom'

function Navbar() {

    const [toggledNavbar, setToggledNavbar] = useState(false)
    const [toggleDropdown, setToggleDropdown] = useState(false)
    const [scrollTop, setScrollTop] = useState(0)

    useEffect(() => {
        
        const onScroll = e => {
          setScrollTop(e.target.documentElement.scrollTop);
        };

        window.addEventListener("scroll", onScroll);
    
        return () => window.removeEventListener("scroll", onScroll);

    }, [scrollTop]);

    return (
        <div className={ scrollTop > 0 ? "navbar-fixed" : "navbar"}>
            <a href='/index.html' className="navbar-brand">
                <img src="../img/logo-white.png" alt="Logo"/>
            </a>
            <div onClick={()=>{ setToggledNavbar(!toggledNavbar) }} className="navbar-toogle">
                Menú
            </div>
            <div className={toggledNavbar ? "navbar-links-sm" : "navbar-links"}>
                <NavLink onClick={()=>{ setToggledNavbar(false) }} activeClassName="active" exact to="/" className="navbar-link">Inicio</NavLink>
                <div onClick={()=>{ setToggleDropdown(!toggleDropdown) }} className="navbar-link">
                    <p>Portales</p>
                    { toggleDropdown && <div className="navbar-dropdown-menu">
                        <a rel="noreferrer" target="_blank" href="https://tobrokerportalasegurados-c0g3cde9hfbdaac9.eastus2-01.azurewebsites.net/auth/login" className="navbar-dropdown-link">Asegurado</a>
                        <a rel="noreferrer" target="_blank" href="https://tobrokerportalagentes.azurewebsites.net/auth/login" className="navbar-dropdown-link">Agentes</a>
                        <a rel="noreferrer" target="_blank" href="https://emisorwebtobroker.azurewebsites.net/login" className="navbar-dropdown-link">Emisor de Taller</a>
                    </div> }
                </div>
                <a href="#benefits" className="navbar-link">Beneficios</a>
                {/* <NavLink onClick={()=>{ setToggledNavbar(false) }} activeClassName="active" exact to="/" className="navbar-link">Beneficios</NavLink> */}
                <a href="#aboutus" className="navbar-link">Nosotros</a>
                {/* <NavLink onClick={()=>{ setToggledNavbar(false) }} activeClassName="active" exact to="/" className="navbar-link">Nosotros</NavLink> */}
                <a href="#testimonials" className="navbar-link">Testimonios</a>
                {/* <NavLink onClick={()=>{ setToggledNavbar(false) }} activeClassName="active" exact to="/" className="navbar-link">Testimonios</NavLink> */}
                <a href="#contact" className="navbar-link">Contáctanos</a>
                <a rel="noreferrer" target="_blank" href="https://www.tobroker.com/account/Login" className="navbar-link">Entrar</a>
                <a rel="noreferrer" target="_blank" href="https://www.tobroker.com/account/Register" className="navbar-link">Registrarse</a>
            </div>
        </div>
    )
}

export default Navbar
