import React, {useState, useEffect} from 'react'

function Home() {

  const slides = [
    {
      title: "Integración con las compañías de seguro",
      text: "Nuestro valor fundamental es la integración tiene con las principales compañía de seguro de país. Que le permite tener al día pólizas nuevas, renovaciones cobros y comisiones. Estas actualizaciones se realizan diariamente.",
      img: "../img/mobile.png"
    },
    {
      title: "Notificaciones",
      text: "Tobroker cuenta con un robusto motor de notificaciones que te permitirá saber en los plazos que tú establezcas pólizas por renovar.",
      img: "../img/web.png"
    },
    {
      title: "Cotizador",
      text: "Cotiza desde un solo lugar y en tiempo real con las seis principales compañías de seguro del país, agiliza los tiempos de respuesta a tus clientes y vende más.",
      img: "../img/laptop.png"
    }
  ]

  const [activeSlide, setActiveSlide] = useState(0);
  
  const [previousSlide, setPreviousSlide] = useState(slides.length - 1);
  const [nextSlide, setNextSlide] = useState(1);

  let benefitsList = [
    {
      icon: "../img/benefit-1.png",
      title: "Plataforma 100% web",
      text: "A través de cualquier navegador estándar e incluso desde celulares, puedes acceder de esta forma y estarás siempre conectado con tus datos, en cualquier momento y en cualquier lugar."
    },
    {
      icon: "../img/benefit-2.png",
      title: "Comisiones",
      text: "Podrás parametrizar correctamente las comisiones pactadas con cada compañía para evitar la captura manual, permitiendo controlar y conciliar puntualmente, para que no existan diferencias en sus comisiones. Y si llegaran a existir, tener control de éstas."
    },
    {
      icon: "../img/benefit-3.png",
      title: "Integración con compañías de seguro",
      text: "Nuestro valor fundamental es la integración con las principales compañías de seguro del país, que le permite tener al día pólizas nuevas, renovaciones, cobros y comisiones. Estas actualizaciones se realizan diariamente."
    },
    {
      icon: "../img/benefit-4.png",
      title: "Innovación constante",
      text: "Cada vez que desarrollamos nuevas funcionalidades se suma a los usuarios del sistema, lo que implica un menor costo de desarrollo y un constante crecimiento de funcionalidades, productos de diferentes realidades, y que son puestas a tu disposición."
    },
    {
      icon: "../img/benefit-5.png",
      title: "Modalidad alquiler",
      text: "Gracias a esta modalidad a un precio muy bajo podrás acceder a una plataforma con todo lo necesario para administrar tus pólizas y renovaciones, endosos, cotizaciones, pagos, solicitudes de cotización y gestión de siniestros, además de una serie de reportes que te ayudarán tu gestión diaria."
    }
  ]

  const HeaderComponent = () => {
    return (
        <div className="header">
            
            <div className="header-grid">
                
                <div className="header-info-container">
                  <span className="header-info-span" ></span>
                  <div className="slides-container">
                    {slides.map((slide, i)=>{
                      return(
                          <div className={i === activeSlide ? 
                                      "slide-box center" 
                                      : i === previousSlide ? 
                                      "slide-box previous" 
                                      : i === nextSlide ? 
                                      "slide-box next" 
                                      : "slide-box"
                          } key={i}>
                          <div className={i === activeSlide ? "slide-box-animation active" : "slide-box-animation"}>
                            <p className="header-title">{slide.title}</p>
                          </div>
                          <div className={i === activeSlide ? "slide-box-animation active" : "slide-box-animation"}>
                            <p className="text-subtitle">{slide.text}</p>
                          </div>
                          <div className={i === activeSlide ? "slide-box-animation active flex-center" : "slide-box-animation flex-center"}>
                            
                            <a className="store-button" target="_blank" href="https://play.google.com/store/apps/details?id=com.tobroker" rel="noreferrer">
                              <img className="" src="../img/play-store.png" alt="A"/>
                            </a>
                            <a className="store-button" target="_blank" href="https://apps.apple.com/pa/app/tobroker/id1667618949" rel="noreferrer">
                              <img className="" src="../img/app-store.png" alt="A"/>
                            </a>

                          </div>
                        </div>
                      )
                    })}
                  </div>
                    
                  
                  <div className="header-btn-container">
                    <div onClick={()=>{ changeActiveSlideAnother(false) }} className="slide-btn">
                      <img src="../img/chevron-left.svg" alt="App store"/>
                    </div>
                    <div onClick={()=>{ changeActiveSlideAnother(true) }} className="slide-btn">
                      <img src="../img/chevron-right.svg" alt="Play store"/>
                    </div>
                  </div>
                  
                  {/* <div className="header-store-btns row no-gutters w-100">
                    <a href="/contact" className="store-btn">
                      <img src="../img/app-store.png" alt="App store"/>
                    </a>
                    <a href="/contact" className="store-btn">
                      <img src="../img/play-store.png" alt="Play store"/>
                    </a>
                  </div> */}

                </div>
                
                <img src="../img/circle-small.png" className={"header-circle header-circle-small " + activeSlide} alt=""/>
                <img src="../img/circle-medium.png" className={"header-circle header-circle-medium " + activeSlide} alt=""/>
                <div className="header-imgs-container">
                  {slides.map((slide, i)=>{
                    return(
                      <div className={i === activeSlide ? 
                        "slide-box center" 
                        : i === previousSlide ? 
                        "slide-box previous" 
                        : i === nextSlide ? 
                        "slide-box next" 
                        : "slide-box"
                      } key={i}>
                          <img className={i === activeSlide ? "slide-box-animation-img active" : "slide-box-animation-img"} src={slide.img} alt="Header"/>
                      </div>
                    )
                  })}
                </div>
                <img src="../img/circle-big.png" className={"header-circle header-circle-big " + activeSlide} alt=""/>
                {/* <img className="header-sparkles" src="../img/sparkles.png" alt="Sparkles"/> */}

            </div>

        </div>
    )  
  }

  const BenefitsComponent = () => {
    return (
        <div id="benefits" className="aboutus">
            <p className="benefits-title">Beneficios Tobroker</p>
            <div className="benefits-grid">
                {benefitsList.map((b, i)=>{
                  return (
                    <BenefitsCard props={b} />
                  )
                })}

            </div>

        </div>
    )  
  }
  
  const BenefitsCard = ({props}) => {
    return (
      <div className='benefit-card'>
        <div className="benefit-card-icon">
          <img alt="benefit" src={props.icon} />
        </div>
        <p className="benefit-card-title">{props.title}</p>
        <p className="benefit-card-text">{props.text}</p>
      </div>
    )
  }

  const AboutUs = () => {
    return (
      <div id="aboutus" className="aboutus mb-5">
        <p className="aboutus-title">Acerca de nosotros</p>
        <p className="aboutus-text">Somos una empresa con más de 10 años de experiencia en el diseño, desarrollo e implementación de software con amplia experiencia en la utilización de Microsoft NET, ofreciéndoles a nuestros clientes el obtener una ventaja competitiva a través de la automatización de procesos.</p>
        <div className="aboutus-grid">
          <div className="aboutus-grid-info">
            <p className="aboutus-grid-info-title">¿Para quiénes?</p>
            <p className="aboutus-grid-info-text">Agencias</p>
            <p className="aboutus-grid-info-text">Broker de Seguros</p>
            <p className="aboutus-grid-info-text">Corredores independientes</p>
            
          </div>
          
          <div className="aboutus-grid-img">
            <img src="../img/rompe.png" alt="Acerca de nosotros" />
          </div>

        </div>
      </div>
    )
  }

  const PortalAgents = () => {
    return (
      <div className="portal-agents">

        <div className="portal-agents-grid">

          <div className="portal-agents-col" style={{width: "75%"}}>
            <p className="portal-agents-title">Portal de Agentes</p>
            <p className="portal-agents-subtitle">Permite que tus agentes tengan acceso a su cartera para facilitarles</p>
            
            <div className="portal-agents-col-text-container">
              <p className="portal-agents-text">
                Acceso ilimitado 24/7 
              </p>
              <p className="portal-agents-text">
                Visbilidad de las polizas nuevas y renovaciones 
              </p>
              <p className="portal-agents-text">
                Reportes de ventas 
              </p>
              <p className="portal-agents-text">
                Reportes de comisiones generadas 
              </p>
              <p className="portal-agents-text">
                Listado de polizas por renovar 
              </p>
              <p className="portal-agents-text">
                Análisis de morosidad
              </p>
            </div>

            <img src="../img/web-no-header.png" className="portal-agents-col-img" alt="Portal Agentes"/>
            <img src="../img/mobile-no-header.png" className="portal-agents-img" alt="Portal Agentes"/>

          </div>
          <div className="portal-agents-col">
            <p className="portal-agents-title">App Movil Corredores</p>
            <p className="portal-agents-subtitle">Accede a tu cartera desde Tobroker Movil</p>
            <div className="portal-agents-col-text-container">
              <p className="portal-agents-text">Acceso ilimitado 24/7 </p>
              <p className="portal-agents-text">Cotizador de Automovil </p>
              <p className="portal-agents-text">Cotizador de Salud </p>
              <p className="portal-agents-text">Visbilidad de las polizas nuevas y renovaciones</p>
              <p className="portal-agents-text">CRM Indicadores de gestion </p>
              <p className="portal-agents-text">Ventas Nuevas </p>
              <p className="portal-agents-text">Comisiones </p>
              <p className="portal-agents-text">Cobros </p>
              <p className="portal-agents-text">Reclamos </p>
              <p className="portal-agents-text">Tareas </p>
              <p className="portal-agents-text">Visualización de documentos de los asegurados</p>
            </div>

          </div>

        </div>
        
        <img src="../img/circle-medium.png" className="portal-circle-medium" alt="Portal Agentes"/>
        <img src="../img/circle-small.png" className="portal-circle-small" alt="Portal Agentes"/>

      </div>
    )
  }

  const SmartPortal = () => {
    return (
      <div className="smart-portal">
        <div className="smart-portal-container">
          
          <div className="smart-portal-info-container">
            
            <p className="aboutus-grid-info-title" style={{textAlign: "left"}}>YoCotizo PLUS</p>
            <div className="smart-portal-text-container">
              <div className="smart-portal-text-img-container">
                <img className="smart-portal-text-img" src="../img/chevron-right-blue.svg" alt="Icon" />
              </div>
              <p className="smart-portal-text">Cotizas con las principales aseguradoras desde un solo sitio</p>
            </div>
            <div className="smart-portal-text-container">
              <div className="smart-portal-text-img-container">
                <img className="smart-portal-text-img" src="../img/chevron-right-blue.svg" alt="Icon" />
              </div>
              <p className="smart-portal-text">Seguimiento automático de leads para cierre de oportunidades</p>
            </div>
            <div className="smart-portal-text-container">
              <div className="smart-portal-text-img-container">
                <img className="smart-portal-text-img" src="../img/chevron-right-blue.svg" alt="Icon" />
              </div>
              <p className="smart-portal-text">Fácil integración con el sistema del corredor</p>
            </div>
            <div className="smart-portal-text-container">
              <div className="smart-portal-text-img-container">
                <img className="smart-portal-text-img" src="../img/chevron-right-blue.svg" alt="Icon" />
              </div>
              <p className="smart-portal-text">Fácil generación de informes en formatos Pdf, Word, Excel, Powerpoint</p>
            </div>
            <div className="smart-portal-text-container">
              <div className="smart-portal-text-img-container">
                <img className="smart-portal-text-img" src="../img/chevron-right-blue.svg" alt="Icon" />
              </div>
              <p className="smart-portal-text">Disminuye considerablemente los tiempos de respuesta al cliente</p>
            </div>
            <div className="smart-portal-text-container">
              <div className="smart-portal-text-img-container">
                <img className="smart-portal-text-img" src="../img/chevron-right-blue.svg" alt="Icon" />
              </div>
              <p className="smart-portal-text">Integrado a las compañías de seguro</p>
            </div>

          </div>
          <div className="smart-portal-img-container">
            <img src="../img/girl.png" alt="Icon" />
          </div>

        </div>
        <div className="smart-portal-container">
        
          <div className="smart-portal-img-container">
            <img src="../img/hands.png" alt="Icon" />
          </div>
          <div className="smart-portal-info-container">
            
            <p className="aboutus-grid-info-title" style={{textAlign: "left"}}>Smart portal BI</p>
            <div className="smart-portal-text-container">
              <div className="smart-portal-text-img-container">
                <img className="smart-portal-text-img" src="../img/chevron-right-blue.svg" alt="Icon" />
              </div>
              <p className="smart-portal-text">Visualización de las renovaciones y evaluación continua de los indicadores de gestión</p>
            </div>
            <div className="smart-portal-text-container">
              <div className="smart-portal-text-img-container">
                <img className="smart-portal-text-img" src="../img/chevron-right-blue.svg" alt="Icon" />
              </div>
              <p className="smart-portal-text">Seguimiento oportuno de la cobranza para alcanzar la meta del mes</p>
            </div>
            <div className="smart-portal-text-container">
              <div className="smart-portal-text-img-container">
                <img className="smart-portal-text-img" src="../img/chevron-right-blue.svg" alt="Icon" />
              </div>
              <p className="smart-portal-text">Implemente estrategias de mercadeo y evalúe el desempeño de sus ejectutivos</p>
            </div>
            <div className="smart-portal-text-container">
              <div className="smart-portal-text-img-container">
                <img className="smart-portal-text-img" src="../img/chevron-right-blue.svg" alt="Icon" />
              </div>
              <p className="smart-portal-text">Flujo de caja estimados según frecuencia de pagos establecidas en las pólizas</p>
            </div>
            <div className="smart-portal-text-container">
              <div className="smart-portal-text-img-container">
                <img className="smart-portal-text-img" src="../img/chevron-right-blue.svg" alt="Icon" />
              </div>
              <p className="smart-portal-text">Comparativos por año y de productos</p>
            </div>

          </div>

        </div>
      </div>
    )
  }
  
  const Testimonials = () => {
    return (
      <div id="testimonials" className="testimonials">
        <p className="testimonials-title">Testimonios</p>
        <p className="testimonials-text">Estos testimonios realmente construyen una historia de Tobroker</p>
        
        <div className="testimonials-grid">
          <iframe width="496" height="400" src="https://www.youtube.com/embed/gceqTGnxAqU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
          <iframe width="496" height="400" src="https://www.youtube.com/embed/ARFquRtMeLo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
          <iframe width="496" height="400" src="https://www.youtube.com/embed/2Oq40z0QxG4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
        </div>
      </div>
    )
  }
  
  const Contact = () => {
    return (
      <div id="contact" className="contact">
        <div className="contact-form-container">
            <div id="hubspotForm"></div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src='https://share.hsforms.com/1w-G8-qPbRT2uNXEvFQUwuAcmaz4';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
        // @TS-ignore
        if (window.hbspt) {
            // @TS-ignore
            window.hbspt.forms.create({
                region: "na1",
                portalId: "21196048",
                formId: "c3e1bcfa-a3db-453d-ae35-712f150530b8",
                target: '#hubspotForm'
            })
        }
    });
  });

  return (
    <>
      <HeaderComponent/>
      <BenefitsComponent/>
      <AboutUs/>
      <PortalAgents/>
      <SmartPortal/>
      <Testimonials/>
      <Contact/>
    </>
  )

   
  function changeActiveSlideAnother(direction){
    if(direction){
      //Si el boton es siguiente
      setActiveSlide(slides.length === (activeSlide + 1) ? 0 : activeSlide + 1)
      setPreviousSlide( previousSlide === (slides.length - 1) ? 0 : previousSlide + 1)
      setNextSlide( nextSlide === (slides.length - 1) ? 0 : nextSlide + 1)
    }else{
      //Si el boton es anterior
      setActiveSlide(activeSlide === 0 ? (slides.length - 1) : activeSlide - 1)
      setPreviousSlide( previousSlide === 0 ? slides.length - 1 : previousSlide - 1)
      setNextSlide( nextSlide === 0 ? slides.length - 1 : nextSlide - 1)
    }
  }

}

export default Home